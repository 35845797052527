// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-docs-js": () => import("/opt/build/repo/src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-analytics-js": () => import("/opt/build/repo/src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-assets-js": () => import("/opt/build/repo/src/pages/assets.js" /* webpackChunkName: "component---src-pages-assets-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-damage-report-js": () => import("/opt/build/repo/src/pages/damageReport.js" /* webpackChunkName: "component---src-pages-damage-report-js" */),
  "component---src-pages-docs-js": () => import("/opt/build/repo/src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-download-js": () => import("/opt/build/repo/src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-help-js": () => import("/opt/build/repo/src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-js": () => import("/opt/build/repo/src/pages/issues.js" /* webpackChunkName: "component---src-pages-issues-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-release-notes-js": () => import("/opt/build/repo/src/pages/releaseNotes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-users-js": () => import("/opt/build/repo/src/pages/users.js" /* webpackChunkName: "component---src-pages-users-js" */),
  "component---src-pages-workshop-js": () => import("/opt/build/repo/src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */)
}

